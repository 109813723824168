<template>
  <div class="StaPers">
    人事信息统计
  </div>
</template>

<script>
export default {
  name: 'StaPers',
  data () {
    return {

    };
  },
  methods: {}
}
</script>

<style scoped>

</style>