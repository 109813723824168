<template>
  <div class="PerTrain">
    员工培训
  </div>
</template>

<script>
export default {
  name: 'PerTrain',
  data () {
    return {

    };
  },
  methods: {}
}
</script>

<style scoped>

</style>