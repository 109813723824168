<template>
  <div class="StaRecord">
    人事记录统计
  </div>
</template>

<script>
export default {
  name: 'StaRecord',
  data () {
    return {

    };
  },
  methods: {}
}
</script>

<style scoped>

</style>