<template>
  <div class="PerEmp">
    员工资料
  </div>
</template>

<script>
export default {
  name: 'PerEmp',
  data () {
    return {

    };
  },
  methods: {}
}
</script>

<style scoped>

</style>